<template>
  <div class="page">
    <Loader v-if="loader"></Loader>
    <div v-if="!loader" class="container fixed">
      <div class="page__title_main with-margin">Ваши данные</div>
      <div class="container__form with-margin">
        <RedactField
          v-for="(field, index) in fields"
          :field="field"
          :error="errors[field.name]"
          v-model="field.value"
          :key="'field_' + index"
        />
      </div>
      <Button @click="submit" text="Отправить"></Button>
    </div>
  </div>
</template>

<script>
import Button from "@/components/common/Button.vue";
import Loader from "@/components/4laps/Loader.vue";
import RedactField from "@/components/4laps/RedactField.vue";
import json from "@/DUMP/fourLaps/setInfo.fields.json";

import { notification } from "ant-design-vue";
import { axiosSimpleWrapper } from "../../helpers/dataHelpers";
import { parse, parsePhoneNumber } from "libphonenumber-js";
import { logDebug } from "@/helpers/logHelper";

export default {
  name: "SetInfo",
  components: {
    Button,
    Loader,
    RedactField,
  },
  data() {
    return {
      loader: true,
      fields: [],
      errors: {},
      phone: "",
      uuid: "",
      code: "",
    };
  },
  mounted() {
    this.fields = json;
    if (!this.$route.query.phone || !this.$route.query.uuid) {
      this.$router.push("/registration");
    } else {
      this.phone = this.$route.query.phone;
      this.uuid = this.$route.query.uuid;
      // ? check
      this.code = this.$route.query.code;
    }
    this.fields.forEach((item) => {
      if (item.name === "phone") {
        item.value = this.phone;
      }
    });
    this.loader = false;
  },
  watch: {},
  methods: {
    validate() {
      this.errors = {};
      this.fields.forEach((field) => {
        if (field.required) {
          if (!field.value) {
            field.value = null;
          }
          if (field.required && !field.value) {
            this.errors[field.name] = "Необходимо заполнить данные";
          }
          if (field.value && field.min && field.value.length < field.min) {
            this.errors[
              field.name
            ] = `Минимальное количество знаков: ${field.min}`;
          }
          if (field.value && field.max && field.value.length < field.max) {
            this.errors[
              field.name
            ] = `Максимальное количество знаков: ${field.max}`;
          }
        } else {
          if (field.value && field.type === "phone") {
            const phoneNumber = parsePhoneNumber(field.value, "RU");
            if (!phoneNumber.isPossible()) {
              this.errors[field.name] = "Не существующий номер";
            }
          }
        }
      });
      return Object.keys(this.errors).length === 0;
    },
    submit() {
      if (this.validate()) {
        this.loader = true;
        const data = {
          sms_code: this.code,
        };
        this.fields.forEach((item) => {
          if (item.name === "phone") {
            const parsePhone = parse(item.value, "RU");
            if (parsePhone.phone) {
              data[item.name] = parsePhone.phone;
            } else {
              data[item.name] = item.value;
            }
          } else if (item.name === "phone_2") {
            if (item.value) {
              data[item.name] = item.value;
            }
          } else if (item.name === "dop_name") {
            if (item.value) {
              data[item.name] = item.value;
            }
          } else {
            data[item.name] = item.value;
          }
          // data[item.name] = item.value;
        });
        const req = {
          method: "post",
          url: "/pets/" + this.$route.query.uuid,
          data,
        };
        axiosSimpleWrapper(
          req,
          (response) => {
            if (response.data.success) {
              notification.open({
                message: response.data.message,
              });
              this.$router.push({
                path: "/finish",
                query: { uuid: this.uuid },
              });
            } else {
              notification.open({
                message: response.data.message,
              });
            }
            this.loader = false;
          },
          (err) => {
            logDebug(err);
            // this.$router.push("/error");
          }
        );
      }
    },
  },
};
</script>

<style scoped lang="scss"></style>
